/** @jsx jsx */
import {jsx, css} from '@emotion/core'

const ListNoStyle = ({children}) => (
  <ul css={css`
    padding: 0;
    margin: 0;
    list-style: none;
    margin-bottom: 0;
  `}>
    {children}
  </ul>
)

export default ListNoStyle;