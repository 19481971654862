// eslint-disable-next-line
import React from "react"
import { Location } from '@reach/router';
import PropTypes from "prop-types"
/** @jsx jsx */
import {jsx, css, Global} from '@emotion/core'

// Components
import MainNav from "./organisms/main-nav"

// Images
import PaperTexture from '../images/_graphic/beverly-hubbard-author-paper-texture-orlando-florida.jpg'

// Data
const navData = [
  {
    linkName: 'Author',
    linkLocation: '/author',
  },
  {
    linkName: 'Books',
    linkLocation: '/books/the-story-of-the-bible',
  },
  {
    linkName: 'Study Guides',
    linkLocation: '/study-guides/the-story-of-the-bible',
  },
  {
    linkName: "Bev's Blog",
    linkLocation: "/bevs-blog",
  },
  {
    linkName: 'Contact',
    linkLocation: '/contact',
  },

]

const Layout = ({ children }) => (
  <>
    <Global styles={css`
      body {
        position: relative;
        margin: 0;
        background: url(${PaperTexture}) repeat;
        font-size: 75%;
        @media (min-width: 768px) {
          font-size: 75%;
        }
        @media (min-width: 992px) {
          font-size: 75%;
        }
        @media (min-width: 1024px) {
          font-size: 75%;
        }
        @media (min-width: 1350px) {
          font-size: 80%;
        }
        @media (min-width: 1700px) {
          font-size: 100%;
        }
      }
    `}/>
    <Location>
      {({location}) => (
        <MainNav 
          data={navData}
          large={location.pathname === '/' ? true : false}
        />
      )}
    </Location>
    {children}
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
