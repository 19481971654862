/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import styled from '@emotion/styled'

// Components
import MediaQuery from 'react-responsive'
import Header4 from '../atoms/header-4';
import Quote from '../atoms/quote';
import Link1 from '../atoms/link-1';

// Images
import ImgFooterFlowerStrip from '../images/_footer/img-footer-flower-strip';
import ImgFooterPortrait from '../images/_footer/img-footer-portrait';
import ImgSignature from '../images/_logo/img-signature';

// Styles
const Container = styled.footer`
  position: relative;
  width: auto;
`
const Background = styled.div`
  z-index: -1;
  position: absolute;
  height: 100%;
  width: 100%;
`

// Styles for top image bar
const ContainerTopImage = styled.section`
  position: relative;
  height: 20vw;
  max-height: 250px;
  min-height: 150px;
  width: 100%;
  > div {
    position: absolute!important;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
`

// Styles for signature section
const ContainerSignature = styled.section`
  position: relative;
  background: #FFFFFF;
  height: 40vw;
  min-height: 200px;
  max-height: 500px;
  width: auto;
  @media(max-width: 860px) {
    font-size: 80%;
  }
  @media(max-width: 700px) {
    font-size: 70%;
  }
  @media(max-width: 450px) {
    font-size: 60%;
  }
`
const ContainerSignatureImage = styled.section`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 50%;
  max-width: 900px;
  overflow: hidden;
  @media (max-width: 600px) {
    width: 300px;
    left: -125px;
  }
  @media (max-width: 500px) {
    width: 250px;
    left: -125px;
  }
  @media (max-width: 420px) {
    width: 250px;
    left: -100px;
  }
`
const ContainerSignatureQuote = styled.section`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0;
  top: 0;
  height: 100%;
  width: 50%;
  text-align: center;
  @media (max-width: 600px) {
    width: 70%;
    right: 0px;
  }
  @media (max-width: 500px) {
    height: 80%;
    right: 0px;
  }
`
const ContainerSignatureWriting = styled.section`
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 25vw;
  bottom: 13%;
  @media (max-width: 500px) {
    bottom: 10%;
    width: 150px;
  }
`
const ContainerSignatureCopyright = styled.section`
  position: absolute;
  left: calc(200px + 20vw);
  right: 0;
  margin: 0 auto;
  max-width: 200px;
  bottom: 15%;
  text-align: center;
  @media (max-width: 500px) {
    width: 80px;
    bottom: 10%;
    left: calc(20px + 40vw);
  }
`
// Styles for bottom bar
const ContainerNavigation = styled.section`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20vw;
  max-height: 250px;
  width: 100%;
  @media(max-width: 700px) {
    font-size: 80%;
  }
  @media(max-width: 500px) {
    font-size: 70%;
  }
  @media(max-width: 380px) {
    font-size: 50%;
  }
`
const ContainerNavigationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 30px;
`


const Footer = ({image}) => (
  <Container>
    {image && 
      <ContainerTopImage>
        {image}
      </ContainerTopImage>
    }
    
    <ContainerSignature>
      <ContainerSignatureImage>
        <ImgFooterPortrait/>
      </ContainerSignatureImage>

      <ContainerSignatureQuote>
        <MediaQuery query="(min-width: 500px)">
          <Quote align='center'>"You, God, are my God, earnestly I seek you;<br/>I thirst for you..."<br/>Psalm 63:1</Quote>
        </MediaQuery>
        <MediaQuery query="(max-width: 499px)">
          <Quote align='center'>"You, God, are my God,<br/>earnestly I seek you;<br/>I thirst for you..."<br/>Psalm 63:1</Quote>
        </MediaQuery>
      </ContainerSignatureQuote>

      <ContainerSignatureWriting>
        <ImgSignature/>
      </ContainerSignatureWriting>
      <ContainerSignatureCopyright>
        <Header4 fontSize={'0.925em'}>Copyright © 2019</Header4>
      </ContainerSignatureCopyright>
    </ContainerSignature>

    <ContainerNavigation>
      <Background>
        <ImgFooterFlowerStrip/>
      </Background>

      <ContainerNavigationWrapper>
        <Link1 href='/contact' color='#E0E27D' fontSize='2em' fontWeight='lighter' fontFamily='Palanquin'>Send a Message</Link1>

        <div css={css`>a{margin-left: 30px;}`}>
          <Link1 href='/privacy-policy' color='#E0E27D' fontSize='2em' fontWeight='lighter' fontFamily='Palanquin'>Privacy Policy</Link1>
          <Link1 href='/terms-of-service' color='#E0E27D' fontSize='2em' fontWeight='lighter' fontFamily='Palanquin'>Terms of Service</Link1>
        </div>
      </ContainerNavigationWrapper>
    </ContainerNavigation>
  </Container>
)

export default Footer