/** @jsx jsx */
import {jsx, css} from '@emotion/core'

const Header4 = ({
  children, 
  align='left', 
  color='#032E47', 
  fontFamily='Dancing Script', 
  fontSize='1.25em', 
  spacing='0px'
}) => (
  <h4
    css={css`
      color: ${color};
      font-family: ${fontFamily};
      font-size: ${fontSize};
      margin: 0 0 ${spacing} 0;
      text-align: ${align};
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
    `}
  >
    {children}
  </h4>
)

export default Header4