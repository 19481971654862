// eslint-disable-next-line
import React from 'react'
import styled from '@emotion/styled'

// Components
import MediaQuery from 'react-responsive';
import { slide as Menu } from 'react-burger-menu'
import ListNoStyle from '../atoms/list-no-style'
import ItemNoStyle from '../atoms/item-no-style'
import Link1 from '../atoms/link-1'

// Images
import PaperTexture from '../../images/_graphic/beverly-hubbard-author-paper-texture-orlando-florida.jpg'

// Mobile Styles
const mobileStyles = {
  bmBurgerButton: {
    position: 'absolute',
    padding: '10px',
    width: '15px',
    height: '15px',
    right: '30px',
    top: '35px',
    background: `transparent`,
    mixBlendMode: 'normal',
  },
  bmBurgerBars: {
    background: '#745077'
  },
  bmBurgerBarsHover: {
    opacity: '0.2'
  },
  bmCrossButton: {
    height: '24px',
    width: '24px',
    left: '15px',
  },
  bmCross: {
    background: '#bdc3c7'
  },
  bmItemList: {
    display: 'block',
  },
  bmMenuWrap: {
    top: '0',
    background: `url(${PaperTexture})`,
  },
  bmMenu: {
    overflowX: 'hidden',
  },
  bmOverlay: {
    top: '0',
    left: '0'
  },
}

const MobileContainer = styled.section`
  display: flex;
  justify-content: center;
  padding: 60px 30px;
  text-align: center;
  font-size: 120%;
  outline: none;
`

const MainLinks = ({data, stacked=true}) => (
  <>
    <MediaQuery query="(min-width: 992px)">
      {stacked &&
        <ListNoStyle>
          {data && 
            data.map((link, i) => (
              <div key={i}>
                <MediaQuery query="(min-width: 1400px)">
                  <ItemNoStyle key={i} spacing='30px'>
                    <Link1 href={link.linkLocation} fontSize='1.5em'>{link.linkName}</Link1>
                  </ItemNoStyle>
                </MediaQuery>
                <MediaQuery query="(max-width: 1399px)">
                  <ItemNoStyle key={i} spacing='30px'>
                    <Link1 href={link.linkLocation} fontSize='1.375em'>{link.linkName}</Link1>
                  </ItemNoStyle>
                </MediaQuery>
              </div>
            ))
          }
        </ListNoStyle>
      }
      {!stacked && 
        <ListNoStyle>
          {data && 
            data.map((link, i) => (
              <ItemNoStyle key={i} vertical={false} spacing={'60px'}>
                <Link1 href={link.linkLocation} fontSize='1.5em'>{link.linkName}</Link1>
              </ItemNoStyle>
            ))
          }
        </ListNoStyle>
      }
    </MediaQuery>
    <MediaQuery query="(max-width: 991px)">
      <Menu styles={mobileStyles} right alt='mobile menu button'>
        <MobileContainer>
          <ListNoStyle>
              {data && 
                data.map((link, i) => (
                  <ItemNoStyle key={i}>
                    <Link1 href={link.linkLocation} fontSize='1.25em'>{link.linkName}</Link1>
                  </ItemNoStyle>
                ))
              }
            </ListNoStyle>
          </MobileContainer>
      </Menu>
    </MediaQuery>
  </>
)


export default MainLinks