/** @jsx jsx */
import {jsx, css} from '@emotion/core'

const ItemNoStyle = ({children, vertical=true, spacing='45px'}) => {
  if(vertical) {
    return(
      <li css={css`
        padding: 0;
        margin: 0 0 ${spacing} 0;
      `}>
        {children}
      </li>
    )
  }
  else {
    return(
      <li css={css`
        display: inline-block;
        padding: 0;
        margin: 0 ${spacing} 0 0;
        &:last-of-type {
          margin: 0;
        }
      `}>
        {children}
      </li>
    )
  }
}

export default ItemNoStyle