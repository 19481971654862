// eslint-disable-next-line
import React from 'react'
import styled from '@emotion/styled'

// Images
import ImgNavBackground from '../../images/_graphic/beverly-hubbard-author-nav-background-orlando-florida.png'
import ImgSignature from '../images/_logo/img-signature'
import ImgLogo from '../images/_logo/img-logo'
import MainLinks from '../molecules/main-links'
import Link1 from '../atoms/link-1'
import ImgAccentPaper from '../images/_graphic/img-accent-paper';

// Styles
const ContainerLogo = styled.div`
  position: absolute;
  top: 25px;
  left: 25px;
  width: 125px;
  @media (max-width: 768px) {
    width: 90px;
    top: 15px;
    left: 15px;
  }
  @media (max-width: 600px) {
    width: 75px;
    top: 22px;
    left: 25px;
  }
`

// Styles For Large Render
const ContainerBackgroundTopImageAccent = styled.div`
  position: absolute;
  width: 100%;
  top: 100%;
  z-index: -1;
  overflow: hidden;
`
const ContainerLarge = styled.nav`
  z-index: 100;
  position: relative;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 350px;
  width: 100%;
  margin-bottom: 0px;
  background: url(${ImgNavBackground}) repeat bottom/cover, #FFFFFF;
  @media(max-width: 1200px) {
    height: 300px;
  }
  @media(max-width: 992px) {
    height: 250px;
  }
`
const ContainerLargeOffset = styled.div`
  width: 250px;
`
const ContainerLargeSignature = styled.div`
  width: 400px;
  max-width: 400px;
  @media (max-width: 768px) {
    right: 0;
    left: 0;
    margin: 0 auto;
    position: absolute;
    margin-top: 30px;
    padding: 0 30px;
    width: calc(100% - 60px);
  }
`
const ContainerLargeLinks = styled.div`
  width: 250px;
  margin-bottom: 0px;
`
// Styles For Small Render
const ContainerSmall = styled.nav`
  z-index: 100;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 175px;
  width: 100%;
  margin-bottom: -2px;
  background: url(${ImgNavBackground}) bottom/cover;
  transition: 0.5s;
  @media (max-width: 768px) {
    height: 100px;
  }
`
const ContainerSmallSignature = styled.div`
  width: 400px;
  margin-bottom: 15px;
  @media(max-width: 991px) {
    margin-top: 45px;
  }
  @media (max-width: 768px) {
    width: 300px;
    margin-top: 30px;
  }
  @media (max-width: 600px) {
    width: 200px;
    margin-top: 30px;
  }
  @media (max-width: 450px) {
    display: none;
  }
  
`
const ContainerSmallLinks = styled.div`
  margin-bottom: 0px;
`


class MainNav extends React.Component {
  constructor(props) {
    super(props)
  }

  
  render() {
    const {
      data, 
      large=true,
    } = this.props

    if(large) {
      return(
        <>
          <ContainerLarge>

                <ContainerLogo>
                  <Link1 href='/'>
                    <ImgLogo/>
                  </Link1>
                </ContainerLogo>


            <ContainerLargeOffset/>

                <ContainerLargeSignature>
                  <ImgSignature/>
                </ContainerLargeSignature>

                <ContainerLargeLinks>
                  <MainLinks data={data}/>
                </ContainerLargeLinks>

            <ContainerBackgroundTopImageAccent>
              <ImgAccentPaper/>
            </ContainerBackgroundTopImageAccent>

          </ContainerLarge>
        </>
      )
    }
    else {
      return(
        <ContainerSmall>
          <ContainerLogo>
            <Link1 href='/'>
              <ImgLogo/>
            </Link1>
          </ContainerLogo>

          <ContainerSmallSignature>
            <ImgSignature/>
          </ContainerSmallSignature>

          <ContainerSmallLinks>
            <MainLinks data={data} stacked={false}/>
          </ContainerSmallLinks>
          
          <ContainerBackgroundTopImageAccent>
            <ImgAccentPaper/>
          </ContainerBackgroundTopImageAccent>

        </ContainerSmall>
      )
    }
  }
}

export default MainNav