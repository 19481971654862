/** @jsx jsx */
import { css, jsx } from '@emotion/core'

// Components
import { Link } from "gatsby";

const Link1 = ({
  children, 
  href, 
  color='#745077', 
  fontFamily='Satisfy', 
  fontSize='1.25em', 
  fontWeight='300',
  hoverColor='#CCCCCC', 
  linkid, 
  onMouseOver,
  internalManual = false,
}) => {
  
  // Check if the link is an internal link
  const internal = /^\/(?!\/)/.test(href)

  // Internal Link

  if(internal || internalManual) {
    return(
      <Link
        fade
        name={children}
        hex='#FFFFFF'
        data-link-id={linkid}
        onMouseOver={onMouseOver}
        css={css`
          color: ${color};
          font-family: ${fontFamily};
          font-size: ${fontSize};
          font-weight: ${fontWeight};
          text-decoration: none;
          transition: 0.2s;
          -moz-osx-font-smoothing: grayscale;
          -webkit-font-smoothing: antialiased;
          &:hover {
            color: ${hoverColor}
          }
        `}
        to={href}
      >
        {children}
      </Link>
    )
  }

  // External Link

  else {
    return(
      <a
        name={children}
        target={'_blank'}
        data-link-id={linkid}
        onMouseOver={onMouseOver}
        css={css`
          color: ${color};
          font-family: ${fontFamily};
          font-size: ${fontSize};
          font-weight: ${fontWeight};
          text-decoration: none;
          transition: 0.2s;
          -moz-osx-font-smoothing: grayscale;
          -webkit-font-smoothing: antialiased;
          &:hover {
            color: ${hoverColor}
          }
        `}
        href={href}
      >
        {children}
      </a>
    )
  }
}

export default Link1