/** @jsx jsx*/
import { css, jsx } from '@emotion/core'

const Quote = ({
  children, 
  color,
  align='left',
  spacing='0px',
  fontSize='3em', 
  fontFamily='Italianno',
}) => (
  <p css={css`
    color: ${color};
    font-size: ${fontSize};
    font-family: ${fontFamily};
    text-align: ${align};
    margin: 0 0 ${spacing} 0;
    padding: 50px 0;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  `}>
    {children}
  </p>
)

export default Quote